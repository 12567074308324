.selectgroup {
	display: inline-flex;
}

.selectgroup-item {
	flex-grow: 1;
	position: relative;

	& + & {
		margin-left: -1px;
	}

	&:not(:first-child) .selectgroup-button {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&:not(:last-child) .selectgroup-button {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.selectgroup-input {
	opacity: 0;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
}

.selectgroup-button {
	display: block;
	border: 1px solid $input-border-color;
	text-align: center;
	padding: $input-btn-padding-y 1rem;
	position: relative;
	cursor: pointer;
	border-radius: 3px;
	color: $text-muted;
	user-select: none;
	font-size: $font-size-base;
	line-height: 1.5rem;
	min-width: $input-height;
}

.selectgroup-button-icon {
	padding-left: .5rem;
	padding-right: .5rem;
	font-size: 1rem;
}

.selectgroup-input:checked + .selectgroup-button {
	border-color: $primary;
	z-index: 1;
	color: $primary;
	background: mix(#fff, $primary, 90%)
}

.selectgroup-input:focus + .selectgroup-button {
	border-color: $primary;
	z-index: 2;
	color: $primary;
	box-shadow: $input-btn-focus-box-shadow;
}

.selectgroup-pills {
	flex-wrap: wrap;
	align-items: flex-start;

	.selectgroup-item {
		margin-right: .5rem;
		flex-grow: 0;
	}

	.selectgroup-button {
		border-radius: 50px !important;
	}
}