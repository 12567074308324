.lead {
	line-height: 1.4;
}

a {
	text-decoration-skip-ink: auto;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	a {
		color: inherit;
	}
}

strong,
b {
	font-weight: 600;
}

p,
ul,
ol,
blockquote {
	margin-bottom: 1em;
}

blockquote {
	font-style: italic;
	color: $text-muted-dark;
	padding-left: 2rem;
	border-left: 2px solid $border-color;

	p {
		margin-bottom: 1rem;
	}

	cite {
		display: block;
		text-align: right;

		&:before {
			content: '— ';
		}
	}
}

code {
	background: rgba(0, 0, 0, 0.025);
	border: 1px solid rgba(#000, .05);
	border-radius: 3px;
	padding: 3px;

	pre & {
		padding: 0;
		border-radius: 0;
		border: none;
		background: none;
	}
}

hr {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

pre {
	color: $gray-800;
	padding: 1rem;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	background-color: #f8fafc;
	border-radius: 3px;
	tab-size: 4;
	text-shadow: 0 1px white;
	hyphens: none;
}

img {
	max-width: 100%;
}

.text-wrap {
	font-size: 1rem;
	line-height: 1.66;

	> :first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}

	> {
		h1, h2, h3, h4, h5, h6 {
			margin-top: 1em;
		}
	}

	table {
		@extend .table, .table-bordered;
	}
}

.section-nav {
	background-color: $gray-100;
	margin: 1rem 0;
	padding: .5rem 1rem;
	border: 1px solid $border-color;
	border-radius: 3px;
	list-style: none;

	&:before {
		content: 'Table of contents:';
		display: block;
		font-weight: 600;
	}
}