// Variables
@import "variables";

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "tabler/tabler";
@import "selectize/selectize.default";
@import "select2/dist/css/select2.min";
@import "~font-awesome/scss/font-awesome.scss";

@import url('https://rsms.me/inter/inter.css');

//Vue Multiselect
@import '~vue-multiselect/dist/vue-multiselect.min';

// Toastr
@import '~toastr/toastr';
.toast-success {
    background-color: #40b34f !important;
}
.toast {
  opacity: 1.0 !important;
}

// Custom
// debug element
// * {
//   border: 1px solid #f00 !important;
// }

.container {
    max-width: 90% !important;
}
.list-group-transparent .list-group-item.active {
    background: none;
}
.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.header-brand-img {
    height: auto;
    width: 80%;
}

// bottom-navbar on mobile
@media (max-width: 575px) {
    .header-brand {
        margin-right: 0px !important;
    }
    .nav-tabs {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .nav-tabs .nav-item {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nav-tabs .nav-link {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}

.tiny {
  font-size: 12px;
}

.mockup {
  font-family: 'BLOKK';
}

.card-header {
  margin-bottom: 10px;
}

.card-title {
  font-size: 20px;
}

.m-t {
  margin-top: 10px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-b {
  margin-bottom: 10px;
}

.m-t-md {
  margin-top: 30px;
}

.m-t-lg {
  margin-top: 60px;
}

.m-t-none {
  margin-top: 0px;
}

.m-b-none {
  margin-bottom: 0px;
}

.m-r {
  margin-right: 10px;
}

.m-l {
  margin-left: 10px;
}

.p-none {
  padding: 0px;
}

/* make sidebar nav vertical */ 
@media (min-width: 768px) {
  .sidebar-nav .navbar .navbar-collapse {
    padding: 0;
    max-height: none;
  }
  .sidebar-nav .navbar ul {
    float: none;
    display: block;
  }
  .sidebar-nav .navbar li {
    float: none;
    display: block;
  }
  .sidebar-nav .navbar li a {
    padding-top: 12px;
    padding-bottom: 12px;
  }
    .carousel-item {
        height: 600px;
    }
}

.action-item {
  border-bottom: 1px dotted #e5e5e5;
}

#dashboard {
  position: relative;
}

#spinner {
  // z-index: 1000;
  // position: absolute;
  // top: 65px;
  // left: 50%;
}

.dashboard-header {
  padding-bottom: 60px;
}

.panel h3.pull-left {
  margin-top: 5px;
  margin-bottom: 0px;
}

#branch {
  width: 150px;
}

#timeline {
  width: 180px;
}

#custom-month-timeline, #custom-year-timeline {
  width: 80px;
}

.coach {
  color: #cbb956;
}

.client {
  color: #2ab27b;
}

.panel-value {
  font-size: 16px;
  font-weight: bold;
}

.widget-header {
  font-size: 30px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0px;
}

.widget-value {
  font-weight: normal;
}

.submenu ul {
    margin-top: 11px;
    padding: 0;
}

.submenu li {
    display: inline;
    list-style: none;
}

.submenu li a {
    padding: 0 13px;
    font-weight: 400;
    color: #32325d;
    text-decoration: none;
}

.submenu li.active a {
    font-weight: 700;
    color: #28903B;
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1100px;
    }
}

// Datatables
.pointer {
    cursor: pointer;
}
.VueTables__limit-field {
    float: left;
    margin-right: 1rem;
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

// Tailwind-ish

// width
.w-1r {
    width: 0.25rem;
}
.w-2r {
    width: 0.5rem;
}
.w-3r {
    width: 0.75rem;
}
.w-4r {
    width: 1rem;
}
.w-6r {
    width: 1.5rem;
}
.w-8r {
    width: 2rem;
}
.w-10r {
    width: 2.5rem;
}
.w-12r {
    width: 3rem;
}
.w-16r {
    width: 4rem;
}
.w-24r {
    width: 6rem;
}
.w-32r {
    width: 8rem;
}
.w-48r {
    width: 12rem;
}
.w-64r {
    width: 16rem;
}
.w-ai {
    width: auto !important;
}

.w-10 {
  width: 10% !important;
}

// font size
.text-xxs    { 
    font-size: .625rem !important;
}
.text-xs    { 
    font-size: .75rem !important;
}
.text-sm    { 
    font-size: .875rem !important;
}
.text-base  { 
    font-size: 1rem !important;
}
.text-lg    { 
    font-size: 1.125rem !important;
}
.text-xl    { 
    font-size: 1.25rem !important;
}
.text-2xl   { 
    font-size: 1.5rem !important;
}
.text-3xl   { 
    font-size: 1.875rem !important;
}
.text-4xl   { 
    font-size: 2.25rem !important;
}
.text-5xl   { 
    font-size: 3rem !important;
}

// border radius
.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

// button focus
.focus-0:focus {
    outline: 0;
    box-shadow: none;
}
.focus-0:active {
    outline: 0;
    box-shadow: none;
}

// mobile view for vuetables
#table {
    padding: 1.25rem;
    background-color: white;
}
@media (max-width: 575px) {
    .container {
        padding-left: 0px;
        padding-right: 0px;
    }
    #table {
        padding-left: 0px;
        padding-right: 0px;
    }
    .VueTables__search {
        width: 100% !important;
        padding-left: 1em;
        padding-right: 1em;
    }
}

// buttons
.btn-medicure {
    background-color: #AC2339;
    border-color: #AC2339;
    color: white;
}

// progress-bar
.progress-bar {
    background-color: #61707F;
}

.headerbar {
  background-image: linear-gradient(to left, #820A26 , #C3163B, #DF3847, #E9434B);
  opacity: 100%;
}

.text-default {
  color:#ffffff;
}

//invoice design

.invoice-box {
    background-color: white;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 16px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td{
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(4) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

//print invoice
@media print {
  .btn{
    display: none;
  }

  .form-control{
    display: none;
  }

  a[href]:after {
    content: none !important;
  }

  #sidebar{
    display: none;
  }

  #content{
    margin-left: auto !important;
    margin-right: auto !important;
  }

  #title{
    display: none;
  }

  .badge{
    display: none;
  }
}

// Jom Menyumbang
.container {
    max-width: 1170px !important;
}

.flags::before {
    background-image: url('../images/rect_mate.png');
    background-size: auto 100%;
    border-radius: 0px;
    width: 30px;
    height: 20px;
    content: "";
    vertical-align: middle;
    margin-right: 10px;
    margin-top: -1px;
    display: inline-block;
    overflow: hidden;
}

.malay::before {
    background-position: -3360px 0;
}

.english::before {
    background-position: -1920px 0;
}

.top-navbar {
    background: #0072bc;
}

.header {
    background: #35a1e8;
}

.nav-tabs .nav-link {
    border-bottom: 3px solid transparent;
}

.nav-tabs .nav-link.active {
    border-color: white;
}

.nav-tabs .nav-link:hover:not(.disabled) {
    border-color: white;
    color: #d0e2ed !important;
}
#footer a {
    color: yellow;
}

a.no-deco {
  max-width: 20em;
  flex: 1;
  color: #495057;
}

a.no-deco:hover {
  color: #495057;
  text-decoration: none !important;
}
.mw-100 {
    max-width: 100% !important;
}
.form-control::placeholder {
    color: #adb5bd;
    opacity: 1;
}

// Custom grid
@media (min-width:768px) {
    .col-md-12p {
        flex:0 0 12%;
        max-width:12%
    }
    .col-md-88p {
        flex:0 0 88%;
        max-width:88%
    }
}

// Flash alert
ul.alert-danger > li p:last-child {
    margin-bottom: 0px !important;
}

// Custom Radio/Select Group
// Taken from preview-dev.tabler.io
.custom-form-selectgroup {
    display: inline-flex;
    margin: 0 -.5rem -.5rem 0;
    flex-wrap: wrap; }
    .custom-form-selectgroup .custom-form-selectgroup-item {
        margin: 0 .5rem .5rem 0; }

.custom-form-selectgroup-vertical {
    flex-direction: column; }

.custom-form-selectgroup-item {
    display: block;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) !important; }

.custom-form-selectgroup-input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0; }

.custom-form-selectgroup-label {
    position: relative;
    display: block;
    min-width: calc(1.4285714em + 0.875rem + 2px);
    margin: 0;
    padding: 0.4375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.4285714;
    color: #6e757e;
    background: #ffffff;
    // text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
         -moz-user-select: none;
            -ms-user-select: none;
                    user-select: none;
    border: 1px solid rgba(110, 117, 126, 0.24);
    border-radius: 3px;
    transition: border-color .3s, background .3s, color .3s; }
    .custom-form-selectgroup-label .icon:only-child {
        margin: 0 -.25rem; }
    .custom-form-selectgroup-label:hover {
        color: #232e3c; }

.custom-form-selectgroup-check {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 1px solid rgba(110, 117, 126, 0.16);
    vertical-align: middle; }
    .custom-form-selectgroup-input[type="checkbox"] + .custom-form-selectgroup-label .custom-form-selectgroup-check {
        border-radius: 3px; }
    .custom-form-selectgroup-input[type="radio"] + .custom-form-selectgroup-label .custom-form-selectgroup-check {
        border-radius: 50%; }
    .custom-form-selectgroup-input:checked + .custom-form-selectgroup-label .custom-form-selectgroup-check {
        background-color: #206bc4;
        background-repeat: repeat;
        background-position: center;
        background-size: 1rem;
        border-color: rgba(110, 117, 126, 0.16); }
    .custom-form-selectgroup-input[type="checkbox"]:checked + .custom-form-selectgroup-label .custom-form-selectgroup-check {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/%3e%3c/svg%3e"); }
    .custom-form-selectgroup-input[type="radio"]:checked + .custom-form-selectgroup-label .custom-form-selectgroup-check {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3ccircle r='3' fill='%23ffffff' cx='8' cy='8' /%3e%3c/svg%3e"); }

.custom-form-selectgroup-input:checked + .custom-form-selectgroup-label {
    z-index: 1;
    color: #206bc4;
    background: rgba(32, 107, 196, 0.04);
    border-color: #90b5e2; }

.custom-form-selectgroup-input:focus + .custom-form-selectgroup-label {
    z-index: 2;
    color: #206bc4;
    border-color: #90b5e2;
    box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25); }